.managementContainer {
  background-color: #181a20;
  // padding: 50px;

  // overflow: hidden !important;
  height: calc(100vh - 120px);

  @media (max-width: 1280px) {
    padding-left: 10px;
  }

  @media (max-width: 992px) {
    max-height: calc(100vh - 80px);
    padding: 40px !important;
  }

  @media (max-width: 576px) {
    padding: 20px !important;
  }

  :global(.ant-input) {
    background-color: #1f222a;
    color: white;
    border-radius: 100px;
    border: none;

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
}

.mainHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .searchContainer {
    background-color: #1f222a;
    width: 350px;
    // height: 40px;
    padding: 10px 15px;
    border: none;
    color: white;
    border-radius: 100px;
    gap: 10px;
  }

  .addbuttonMain {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .addBtn {
      padding: 10px 16px 9px 16px;
      font-size: 14px;
      font-weight: 900 !important;
      line-height: 16px;
    }
  }

  .textFormat {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
  }

  .textDate {
    color: #55585a;
    font-size: 12px;
    line-height: 18px;
    padding-left: 10px;
  }

  .textAlldate {
    color: #898f96;
    font-size: 12px;
    line-height: 18px;
    padding-left: 10px;
  }
}

.deletModalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

:global(.ant-modal-wrap) {
  :global(.ant-modal-header) {
    background-color: transparent;
  }

  :global(.ant-modal-close-x) {
    display: none;
  }

  :global(.ant-modal-content) {
    background-color: #1f222a;
  }

  :global(.ant-modal-title) {
    color: #fff;
  }

  :global(.ant-modal-footer) {
    :first-child {
      background-color: transparent;

      span {
        color: #898f96;
        font-size: 16;
        font-weight: 600;
      }

      &:hover {
        border-color: #65c5c2;
      }
    }

    :nth-child(2) {
      background-color: #ff4d4f;
      border-color: #ff4d4f;

      &:hover {
        background-color: #ff4d4f;
        border-color: #ff4d4f;
      }

      span {
        color: white;
        font-size: 16;
        font-weight: 600;
      }
    }
  }
  .modalInput {
    border: 1px solid #2e3133;
    background: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &::placeholder {
      color: gray;
      font-size: 12px;
    }
  }

  .switchContent {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
  }
  :global(.ant-switch-checked) {
    background: #65c5c2 !important;
  }
}

.teamemberTableSkelton {
  margin-top: 40px;
  :global(.ant-table) {
    background-color: transparent;
    table {
      border-spacing: 0 5px;
      padding: 10px;
      background-color: transparent;
      :global(.ant-table-thead) {
        :global(.ant-table-cell) {
          background: transparent;
          border: 0px solid #262a35;
          color: #8d8d8d;
          &::before {
            display: none;
          }
        }
      }
      :global(.ant-table-tbody) {
        :global(.ant-table-row) {
          :global(.ant-table-cell) {
            border: 1px solid #262a35;
            background-color: #1f2732;
            border-left-width: 0px;
            border-right-width: 0px;
            overflow: hidden;
            margin-bottom: 10px;
            &:first-child {
              border-left-width: 1px;
              border-radius: 12px 0 0 12px;
            }
            &:last-child {
              margin-bottom: 5px;
              border-right-width: 1px;
              border-radius: 0 12px 12px 0;
            }
            &::before {
              background-color: transparent;
              width: 0px;
              height: 0px;
              display: none;
            }
          }
        }
      }
    }
  }
}
